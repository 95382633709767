import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { notverifiedimage,ArrowRight } from "../../Constants"
import { Link } from 'react-router-dom'

const AssetNotVerified = () => {
    return (
        <>
            <Header />
                <section className="asset-final-verification">
                    <div className="container">
                        <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 mx-auto px-xl-3">
                            <div className="asset-box">
                                <div className="top-text pt-4 pb-4">
                                    <figure>
                                        <img src={notverifiedimage} alt="" />
                                    </figure>
                                    <h2 className="large-heading px-lg-5 mx-lg-1">ASSET HAS NOT BEEN VERIFIED</h2>
                                    <p className="px-1">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in </p>
                                </div>
                                <Link className="btn primary-btn mx-auto mb-5" to="">Back To Assets <img src={ArrowRight} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}
export default AssetNotVerified;