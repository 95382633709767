import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import "../../assets/css/how-it-work.css"
import { aboutimg, sicon1, sicon2, sicon3, ArrowRight, howitworksimg } from '../../Constants'
import { Link } from 'react-router-dom'
const HowItWorks = () => {
    return (
        <>
            <Header />
            <div className="how-it-work-complete-page">
                {/* <!-- how it works sec Start Here --> */}
                <section className="how-it-work">
                    <div className="container">
                        <div className="banner-content">
                            <div className="row">
                                <div className="col-md-6 wow animate__animated animate__fadeInLeftBig">
                                    <img className="img-fluid" src={howitworksimg} alt="About Img" />
                                </div>

                                <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                    <div className="ps-lg-5">
                                        <h3>Welcome To Asset Registry</h3>
                                        <h2>how it works</h2>
                                        <p>
                                            Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                            Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo
                                            eget
                                            magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat
                                            in
                                            fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras
                                            pulvinar mattis nunc sed. Mattis vulputate enim nulla aliquet porttitor lacus. Vitae nunc
                                            sed
                                            velit dignissim sodales ut eu. Purus non enim praesent elementum facilisis leo. Elementum
                                            <br /><br />
                                            Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                            Adipiscing tristique risus nec feugiat in fermentum.
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- how it works sec End Here --> */}

                {/* <!-- how it works bg sec Start Here --> */}
                <section className="how-it-work-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                <div className="pe-lg-5">
                                    <h3>Welcome To Asset Registry</h3>
                                    <h2>how it works</h2>
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec.
                                    </p>

                                </div>
                            </div>

                            <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                <div className="ps-lg-5">
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo
                                        eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat
                                        in
                                        fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras
                                        pulvinar mattis nunc sed.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- how it works bg sec End Here --> */}

                {/* <!-- how it works bg sec Start Here --> */}
                <section className="how-it-work wow animate__animated animate__fadeInUpBig">
                    <div className="container work-bg">
                        <div className="row">
                            <div className="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <img src={sicon1} alt="icon" />
                                        <h2>Asset registry</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                        <Link to="">Learn More <img src={ArrowRight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <img src={sicon2} alt="icon" />
                                        <h2>Asset registry</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                        <Link to="">Learn More <img src={ArrowRight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <img src={sicon3} alt="icon" />
                                        <h2>Asset registry</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                        <Link to="">Learn More <img src={ArrowRight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- how it works bg sec End Here --> */}

                {/* <!-- how it works bg sec Start Here --> */}
                <section className="progress-div">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                <div className="pe-lg-5">
                                    <h3>Welcome To Asset Registry</h3>
                                    <h2>how it works</h2>
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec.
                                    </p>

                                    <div className="progress-outer">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-info active"></div>
                                            <div className="progress-value"><p>Vitae congue eu Consequat</p><span>90%</span></div>
                                        </div>
                                    </div>
                                    <div className="progress-outer">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-success active"></div>
                                            <div className="progress-value"><p>Vitae congue eu Consequat</p><span>90%</span></div>
                                        </div>
                                    </div>
                                    <div className="progress-outer">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-warning active"></div>
                                            <div className="progress-value"><p>Vitae congue eu Consequat</p><span>90%</span></div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                <div className="ps-lg-5 progress-div-text">
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo
                                        eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat
                                        in
                                        fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras
                                        pulvinar mattis nunc sed.
                                    </p>
                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- how it works bg sec End Here --> */}

                {/* <!-- how it works sec Start Here --> */}
                <section className="how-it-work marg-sec ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 wow animate__animated animate__fadeInRightBig">
                                <div className="pe-lg-5">
                                    <h3>Welcome To Asset Registry</h3>
                                    <h2>how it works</h2>
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo
                                        eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat
                                        in
                                        fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras
                                        pulvinar mattis nunc sed. Mattis vulputate enim nulla aliquet porttitor lacus. Vitae nunc
                                        sed
                                        velit dignissim sodales ut eu. Purus non enim praesent elementum facilisis leo. Elementum
                                        <br /><br />
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum.
                                    </p>

                                </div>
                            </div>
                            <div className="col-md-6 wow animate__animated animate__fadeInLeftBig">
                                <img className="img-fluid" src={howitworksimg} alt="About Img" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- how it works sec End Here --> */}
            </div>
            <Footer />
        </>
    )
}
export default HowItWorks;