import PublicRoutes from './routes/PublicRoutes';
function App() {
  return (
    <>
      <PublicRoutes />
    </>
  );
}

export default App;
