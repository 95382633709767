import React from "react"
import { certificateimg } from "../../Constants"

const Certificate = () => {
    return (
        <>
            <section className="certificate py-5">
                <div className="container">
                    <figure className="col-lg-9 mx-auto">
                        <img src={certificateimg} alt="" className="w-100 img-fluid" />
                    </figure>
                </div>
            </section>
        </>
    )
}
export default Certificate;