import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { ArrowRight } from "../../Constants"
import { Link } from 'react-router-dom'
const MeetingsSchedule = () => {
    let ownername = "John Doe Smith";
    let date = "22 Feb 2023";
    let time = "8:00AM"
    let note = "Vitae congue eu consequat ac felis donec. Platea ";
    const MeetingList = [
        {
            ownername: ownername,
            date: date,
            time: time,
            note: note
        },
        {
            ownername: ownername,
            date: date,
            time: time,
            note: note
        },
        {
            ownername: ownername,
            date: date,
            time: time,
            note: note
        },
        {
            ownername: ownername,
            date: date,
            time: time,
            note: note
        }
    ]
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Authenticator</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <UserSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title d-flex flex-column flex-lg-row justify-content-lg-between gap-2">
                                            <h3>Scheduled Meetings</h3>
                                            <Link to="/newmeeting" className="btn primary-btn">Schedule Meeting <img src={ArrowRight} alt="" /></Link>
                                        </div>
                                        <div class="assets-data">
                                            <div class="assets-table">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="owner-name">Owner Name</th>
                                                            <th class="meeting-date">Meeting Date</th>
                                                            <th class="meeting-time">Meeting Time</th>
                                                            <th class="additional-note text-start ps-5">Additional Note</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {MeetingList.map((asset, index) => (
                                                            <tr key={index}>
                                                                <td className="py-3">{asset.ownername}</td>
                                                                <td className="py-3">{asset.date}</td>
                                                                <td className="py-3">{asset.time}</td>
                                                                <td className="py-3 text-start ps-lg-5 ps-md-3" >{asset.note}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ul class="pagination">
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                <li class="pagi"><a href="#">1</a></li>
                                                <li class="pagi"><a class="active" href="#">2</a></li>
                                                <li class="pagi"><a href="#">3</a></li>
                                                <li class="pagi"><a href="#">4</a></li>
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default MeetingsSchedule;