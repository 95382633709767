import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { pdficon,docimg1,docimg2,docimg3 } from "../../Constants"
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar';
const OwnerVerificationProgress = () => {
    const [value, onChange] = useState(new Date());
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="profile-section">
                <div className="container">
                    <div className="profile-start">
                        <div className="row">
                            <OwnerSidebar />
                            <div className="col-lg-9 col-md-12">
                                <div className="profile_body h-100">
                                    <div className="single_tab ">
                                        <div className="title mb-3 d-flex flex-lg-row flex-column gap-2 justify-content-lg-between align-items-lg-center">
                                            <h3>My Assets</h3>
                                            <div className="asset-status ms-auto">
                                                <span className="question">Status</span>
                                                <span className="verification in-progress">In Progress</span>
                                            </div>
                                        </div>
                                        <div className="verification-details-box">
                                            <div className="heading">
                                                <h3 className="bold-heading">Asset Detail</h3>
                                            </div>
                                            <div className="asset-details">
                                                <div className="row px-1">
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Asset Type</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Year Purchased</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Owner Name</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Previous Owner Name</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Purchase Price</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Current Value</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 px-0">
                                                        <div className="single-data px-2">
                                                            <span className="question">Mortgage Loan Balance</span>
                                                            <span className="answer">Your answer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="asset-documents pt-4">
                                                <div className="heading">
                                                    <h6 className="small-heading">Documents</h6>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={pdficon} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Document Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={pdficon} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Document Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={pdficon} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Document Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={pdficon} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Document Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="asset-documents pt-4">
                                                <div className="heading">
                                                    <h6 className="small-heading">Images</h6>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={docimg1} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Image Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={docimg2} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Image Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="single-dpcumet mb-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-md-8">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <figure>
                                                                            <img src={docimg3} alt="" />
                                                                        </figure>
                                                                        <div className="text-psf ps-lg-3">
                                                                            <span>Image Name</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="link-btn">
                                                                        <Link to="">View</Link>
                                                                        <Link to="">Download</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="about-pdf pt-4">
                                                <div className="single-text-box">
                                                    <h6 className="small-heading">Description</h6>
                                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauc i.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim sodales ut eu. Purus non enim praesent elementum facilisis leo. Elementum facilisis leo vel </p>
                                                </div>
                                                <div className="single-text-box  mt-4">
                                                    <h6 className="small-heading">About Me</h6>
                                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauc i.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerVerificationProgress;