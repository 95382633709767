import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { ArrowRight } from "../../Constants"
import { useNavigate } from 'react-router-dom';
const EditProfile = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/myprofile'); // navigate to the success page
    }
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Authenticator</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <UserSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title">
                                            <h3>My profile</h3>
                                        </div>
                                        <div class="edit-profile-tab mt-3">
                                            <div class="col-lg-10 col-md-12">
                                                <div class="form_start">
                                                    <form onSubmit={handleSubmit}>
                                                        <div class="row">
                                                            <div class="col-md-6 mt-3">
                                                                <label for="FirstName">First Name</label>
                                                                <input type="text" name="Email" className="form-control" id="FirstName" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="LastName">Last Name</label>
                                                                <input type="text" name="LastName" className="form-control" id="LastName" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="EmailAddress">Email Address</label>
                                                                <input type="email" name="Email" className="form-control" id="EmailAddress" placeholder="Enter here" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Address">Phone</label>
                                                                <input type="tel" name="Phone" className="form-control" id="Phone" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-12 mt-3">
                                                                <label for="Address">Address</label>
                                                                <input type="text" name="Address" className="form-control" id="Address" placeholder="Enter here" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex mt-3  align-items-center justify-content-end flex-lg-row flex-column gap-lg-0 gap-4">
                                                            <button class="btn primary-btn center px-4" type="submit">Save Profile <img src={ArrowRight} alt="" /></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default EditProfile;