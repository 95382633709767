import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import "../../assets/css/auth.css"
import { fb, ins, tw, yt, ArrowRight, logo } from "../../Constants"
import { FileUploader } from "react-drag-drop-files"
import { Link, useNavigate } from "react-router-dom"
const AssetOwner = () => {
    const navigate = useNavigate();
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // perform form submission logic here
    //     navigate('/ownerprofile'); // navigate to the success page
    // }
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    // Social Settings Start Here
    return (
        <>
            {/* AssetOwner Start Here */}
            <section class="auth-section start-the-auth">
                <Header />
                <div class="container px-0">
                    <div class="auth-start">
                        <div className="logo-box">
                            <Link to="/">
                                <figure><img src={logo} alt="site-logo" /></figure>
                            </Link>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <div class="from-start h-100 d-flex flex-column justify-content-center position-relative py-4">
                                    <div class="banner-content pt-0 pb-3">
                                        <div class="hedadingDiv">
                                            <h3>Welcome To Asset Registry</h3>
                                            <h1>Sign Up As An Asset Owner</h1>
                                        </div>
                                    </div>
                                    <form action="/ownerprofile">
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Full Name" />
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Email Address" />
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Contact Number" />
                                            </div>
                                            <div className="col-lg-6">
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label htmlFor=""><b>Social Media Profile Link</b></label>
                                                <div className="social-btns">
                                                    <button type="button" className="fb active"><img src={fb} className="img-fluid w-100" alt="" /></button>
                                                    <button type="button" className="ins"><img src={ins} className="img-fluid w-100" alt="" /></button>
                                                    <button type="button" className="yout"><img src={yt} className="img-fluid w-100" alt="" /></button>
                                                    <button type="button" className="twitt active"><img src={tw} className="img-fluid w-100" alt="" /></button>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Facebook Profile Link" />
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Twitter Profile Link" />
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Instagram Profile Link" />
                                            </div>

                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="YouTube Profile Link" />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label htmlFor="DriversLicense">Drivers License</label>
                                                {/* <FileUploader
                                                        handleChange={handleChange}
                                                        name="file"
                                                        id="DriversLicense"
                                                        types={fileTypes}
                                                    /> */}
                                                <label htmlFor="toupload" className="sc-bcXHqe hjYtIJ  ">

                                                    <input type="file" name="" id="toupload" hidden />
                                                    <div class="sc-dkrFOg dmajdp"><span class="sc-hLBbgP bXgVCj"><span>Upload / Drag a file here</span></span><span title="types: JPG,PNG,GIF" class="file-types">JPG,PNG,GIF</span></div>
                                                </label>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Password" />
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Confirm Password" />
                                            </div>
                                        </div>
                                        <p class="small-text">Your password must be up to 12 digits, characters, symbols</p>
                                        <div class="d-flex justify-content-end mb-4 pb-3">
                                            <button class="btn primary-btn px-4">Join Now <img src={ArrowRight} alt="" /></button>
                                        </div>
                                        <div class="terms-line w-100 text-center pt-3 position-absolute bottom-0">
                                            <p>By Joining as authenticator you agree to our <a href="">Terms & conditions</a></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div class="col-md-5 px-md-0">
                                <div class="auth-background">

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </section >
        </>
    )
}
export default AssetOwner;