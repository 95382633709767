import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { userimg,chatimg } from "../../Constants"
import { Link } from 'react-router-dom'

const OwnerChat = () => {
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <OwnerSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div className="chat-application">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="friends-list">
                                                        <div className="single-friends">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>
                                                        <div className="single-friends active">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>
                                                        <div className="single-friends">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>
                                                        <div className="single-friends">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>
                                                        <div className="single-friends">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>
                                                        <div className="single-friends">
                                                            <figure>
                                                                <img src={userimg} alt="" />
                                                            </figure>
                                                            <div className="about-user">
                                                                <div className="username-date">
                                                                    <span className="username">User Name</span>
                                                                    <span className="date">01.01.22</span>
                                                                </div>
                                                                <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="chat-start">
                                                        <div className="chat-body">
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                    <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin dolor, nec sollicitudin libero gravida quis. Duis accumsan risus at accumsan sagittis. Aliquam erat volutpat. Proin viverra in massa sed pharetra. Morbi eu purus sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message send-message">
                                                                <div className="message">
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message send-message">
                                                                <figure>
                                                                    <img src={userimg} alt="" />
                                                                </figure>
                                                                <div className="message">
                                                                    <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin dolor, nec sollicitudin libero gravida quis. Duis accumsan risus at accumsan sagittis. Aliquam erat volutpat. Proin viverra in massa sed pharetra. Morbi eu purus sit amet quam bibendum sollicitudin. Sed eget nulla sed felis sodales tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam mauris nulla, ultrices eget </p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                    <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin dolor, nec sollicitudin libero gravida quis. Duis accumsan risus at accumsan sagittis. Aliquam erat volutpat. Proin viverra in massa sed pharetra. Morbi eu purus sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message send-message">
                                                                <div className="message">
                                                                    <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message send-message">
                                                                <figure>
                                                                    <img src={userimg} alt="" />
                                                                </figure>
                                                                <div className="message">
                                                                    <p>Cras a accumsan ipsum. Aliquam ornare sollicitudin dolor, nec sollicitudin libero gravida quis. Duis accumsan risus at accumsan sagittis. Aliquam erat volutpat. Proin viverra in massa sed pharetra. Morbi eu purus sit amet quam bibendum sollicitudin. Sed eget nulla sed felis sodales tristique. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam mauris nulla, ultrices eget </p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                            <div className="single-message recieved-message">
                                                                <div className="message">
                                                                <p>Lorem ipsum dolor sit amet</p>
                                                                    <span>22:01</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat-footer">
                                                            <input type="text" name="" className="form-control" id="" placeholder="Message...." />
                                                            <button className="send-btn"><img src={chatimg} alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerChat;