import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import "../../assets/css/faqs.css"
const Faqs = () => {
    return (
        <>
            <Header />
            {/* <!-- services sec Start Here --> */}
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Frequently Asked Question</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- services sec End Here --> */}

            <section class="our_team pb-5">
                <div class="container">
                    <div class="col-md-8 mx-auto">
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header " id="panelsStayOpen-headingOne">
                                    <button class="accordion-button for-accordian-heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                    <div class="accordion-body">
                                        <div class="accordian-inner-start">

                                            <p class="text-inside-accordian">
                                            Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla

                                            </p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header " id="panelsStayOpen-headingThree">
                                <button class="accordion-button for-accordian-heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                <div class="accordion-body">
                                    <div class="accordian-inner-start">

                                        <p class="text-inside-accordian">
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header " id="panelsStayOpen-headingFour">
                                <button class="accordion-button for-accordian-heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                <div class="accordion-body">
                                    <div class="accordian-inner-start">

                                        <p class="text-inside-accordian">
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header " id="panelsStayOpen-headingFifth">
                                <button class="accordion-button for-accordian-heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFifth" aria-expanded="false" aria-controls="panelsStayOpen-collapseFifth">
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFifth" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFifth">
                                <div class="accordion-body">
                                    <div class="accordian-inner-start">

                                        <p class="text-inside-accordian">
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate enim nulla 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <Footer />
        </>
    )
}
export default Faqs;