import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { verifiedimage,qrimg,ArrowRight } from "../../Constants"
import { Link } from 'react-router-dom'

const AssetVerified = () => {
    return (
        <>
            <Header />
                <section className="asset-final-verification">
                    <div className="container">
                        <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 mx-auto">
                            <div className="asset-box px-2">
                                <div className="top-text">
                                    <figure>
                                        <img src={verifiedimage} alt="" />
                                    </figure>
                                    <h2 className="large-heading">ASSET HAS BEEN VERIFIED</h2>
                                    <p className="px-xl-5 px-4">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in </p>
                                </div>
                                <div className="only-for-verified">
                                    <div className="asset-details">
                                        <div className="row px-xxl-3">
                                            <div className=" col-md-6 ms-auto">
                                                <div className="single-data">
                                                    <span className="question">Verification Number</span>
                                                    <span className="answer">148972555489</span>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 me-auto">
                                                <div className="single-data">
                                                    <span className="question">Unique Title</span>
                                                    <span className="answer">Your answer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="qr-code-of-asset">
                                        <span>QR Code</span>
                                        <figure>
                                            <img src={qrimg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <Link className="btn primary-btn mx-auto" to="">Back To Assets <img src={ArrowRight} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}
export default AssetVerified;