import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import "../../assets/css/auth.css"
import { FileUploader } from "react-drag-drop-files"
import { useNavigate } from "react-router-dom"
import { ArrowRight } from "../../Constants"
const AssetPayment = () => {
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/assetprogress'); // navigate to the success page
    }
    return (
        <>
            {/* UploadAsset Start Here */}
            <Header />
            <section class="auth-section uploadasset start-the-auth">
                <div class="container">
                <div className="hedadingDiv text-center">
                        <h3>Welcome To Asset Registry</h3>
                        <h2>start authentication</h2>
                        <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu non
                            odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus</p>
                    </div>
                    <div class="auth-start mt-lg-5 mt-md-4 col-lg-10 mx-auto">
                        <div class="row mx-0">
                            <div class="col-md-7 px-lg-0">
                                <div className="heading">
                                    <h4 className="mb-2">Payment</h4>
                                </div>
                                <div class="from-start h-100 d-flex flex-column justify-content-center position-relative">
                                    <form onSubmit={handleSubmit}>
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="CardHolderName">Card Holder Name *</label>
                                                    <input type="text" name="CardHolderName" id="CardHolderName" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="CardNumber">Card Number</label>
                                                    <input type="text" name="CardNumber" id="CardNumber" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="Expiry">Expiry</label>
                                                    <input type="text" name="Expiry" id="Expiry" class="form-control" placeholder="mm/dd/yy" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="CVV">CVV</label>
                                                    <input type="text" name="CVV" id="CVV" class="form-control" placeholder="***" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-end  pb-3 mt-4">
                                            <button class="btn primary-btn px-4">Confirm Payment <img src={ArrowRight} alt="" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-5 px-lg-0 ps-lg-5 mt-4 mt-lg-0 mt-md-0">
                                <div className="payable-payment">
                                    <div className="top-texts">
                                        <h4>Payable amount</h4>
                                        <p>Vitae congue eu consequat ac felis donec. Platea </p>
                                    </div>
                                    <div className="paymentt-list">
                                        <div className="single-payment">
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <span className="question">Service Fees</span>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <span className="answer">$25.00</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-payment">
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <span className="question">Authenticator Fees</span>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <span className="answer">$35.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total-payment">
                                        <div className="single-payment">
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <span className="question">Total Amount</span>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <span className="answer">$35.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default AssetPayment;