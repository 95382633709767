import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
import Calendar from 'react-calendar';
const NewMeeting = () => {
    const [value, onChange] = useState(new Date());
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Authenticator</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="profile-section">
                <div className="container">
                    <div className="profile-start">
                        <div className="row">
                            <UserSidebar />
                            <div className="col-lg-9 col-md-12">
                                <div className="profile_body h-100">
                                    <div className="single_tab ">
                                        <div className="title mb-3">
                                            <h3>Scheduled Meetings</h3>
                                        </div>
                                        <div className="new-meetign-details">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="complete-calender">
                                                        <h6 className="semi-heading">Meeting Details</h6>
                                                        <div className="calender-complete">

                                                            <div className="dat-filter-buttons">
                                                                <button>Day</button>
                                                                <button>Week</button>
                                                                <button className="active">Month</button>
                                                            </div>
                                                            <div className="calender-start mt-3 ">
                                                                <Calendar onChange={onChange} value={value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="set-time">
                                                        <label htmlFor="SetTime">Set Time</label>
                                                        <input type="number" name="SetTime" placeholder="10" id="SetTime" /><span>:</span>
                                                        <input type="number" name="" placeholder="09" id="" /><span>:</span>
                                                        <input type="number" name="SetTim" placeholder="10" max={"60"} id="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-start">
                                                        <h4 className="mb-3">Asset Owner Details</h4>
                                                        <form>
                                                            <div className="form-group mb-3">
                                                                <input type="text" name="" id="" placeholder="Asset Owner Name" className="form-control" />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <textarea name="" id="" placeholder="Additional Note" className="form-control large-textarea" ></textarea>
                                                            </div>
                                                            <div className="form-group mb-3 text-end">
                                                                <button type="submit" className="btn primary-btn ms-auto px-4">Submit <img src={ArrowRight} alt="" /></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default NewMeeting;