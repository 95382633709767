import React from "react"

import Header from "../../components/Header"
import Footer from "../../components/footer"
import "../../assets/css/auth.css"
import { Link, useNavigate } from 'react-router-dom'
import { ArrowRightYellow, logo } from "../../Constants"
const SignIn = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/ownerprofile'); // navigate to the success page
    }
    return (
        <>
            {/* Signin Start Here */}
            <section class="auth-section auth-only">
                <Header />
                <div class="container px-md-0">
                    <div class="auth-start">
                        <div className="logo-box">
                            <Link to="/">
                                <figure><img src={logo} alt="site-logo" /></figure>
                            </Link>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 ps-md-0">
                                <div class="from-start h-100 d-flex flex-column justify-content-center position-relative pt-2 pb-4">
                                    <div class="banner-content pt-0 pb-3">
                                        <div class="hedadingDiv">
                                            <h3>Welcome To Asset Registry</h3>
                                            <h1>lOGIN</h1>
                                            <p class="w-100 mb-0 pb-2">The best place to register your assets
                                            </p>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <input type="text" name="" class="form-control" placeholder="Email" />
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <input type="password" name="" class="form-control" placeholder="Password" />
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <label class="custom_check_box1">Remember Me
                                                <input type="checkbox" />
                                                <span class="checkmark1"></span>
                                            </label>
                                            <Link to="/forgotpassword">Forgot Password ?</Link>
                                        </div>
                                        <div class="d-flex justify-content-end mt-3 mb-4 pb-3">
                                            <button class="btn primary-btn w-100 d-block">Login <img src={ArrowRightYellow} alt="" /></button>
                                        </div>
                                        <div class="terms-line text-center">
                                            <p className="sigin-options">Doesn't Have An Account ? <Link to="/assetowner">Sign Up As Asset Owner</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div class="col-md-5 pe-md-0">
                                <div class="auth-background">
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}
export default SignIn;