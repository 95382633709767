import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
const UserNotifications = () => {
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Authenticator</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="profile-section">
                <div className="container">
                    <div className="profile-start">
                        <div className="row">
                            <UserSidebar />
                            <div className="col-lg-9 col-md-12">
                                <div className="profile_body h-100">
                                    <div className="single_tab ">
                                        <div className="title">
                                            <h3>Notifications</h3>
                                        </div>
                                        <div className="notification-list">
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-10 mt-4 pe-xl-5">
                                                    <div className="single-notification">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Asset Type</span>
                                                                    <span className="answer">Your Answer</span>
                                                                    <span className="date">15 January 2023  |  8:00 AM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Owner Name</span>
                                                                    <span className="answer">John Doe Smith</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="show-details text-end pe-lg-2">
                                                                    <Link to="" className="btn primary-btn">View Details <img src={ArrowRight} alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-10 mt-4 pe-xl-5">
                                                    <div className="single-notification">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Asset Type</span>
                                                                    <span className="answer">Your Answer</span>
                                                                    <span className="date">15 January 2023  |  8:00 AM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Owner Name</span>
                                                                    <span className="answer">John Doe Smith</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="show-details text-end pe-lg-2">
                                                                    <Link to="" className="btn primary-btn">View Details <img src={ArrowRight} alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-10 mt-4 pe-xl-5">
                                                    <div className="single-notification">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Asset Type</span>
                                                                    <span className="answer">Your Answer</span>
                                                                    <span className="date">15 January 2023  |  8:00 AM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Owner Name</span>
                                                                    <span className="answer">John Doe Smith</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="show-details text-end pe-lg-2">
                                                                    <Link to="" className="btn primary-btn">View Details <img src={ArrowRight} alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-10 mt-4 pe-xl-5">
                                                    <div className="single-notification">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Asset Type</span>
                                                                    <span className="answer">Your Answer</span>
                                                                    <span className="date">15 January 2023  |  8:00 AM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="notifcation-data">
                                                                    <span className="question">Owner Name</span>
                                                                    <span className="answer">John Doe Smith</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="show-details text-end pe-lg-2">
                                                                    <Link to="" className="btn primary-btn">View Details <img src={ArrowRight} alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="pagination">
                                            <li class="arrow"><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                            <li class="pagi"><a href="#">1</a></li>
                                            <li class="pagi"><a class="active" href="#">2</a></li>
                                            <li class="pagi"><a href="#">3</a></li>
                                            <li class="pagi"><a href="#">4</a></li>
                                            <li class="arrow"><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default UserNotifications;