import React, { useState } from "react"
// import Header from "../../components/Header"
import OwnerHeader from "../../components/OwnerHeader"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
import "../../assets/css/profile.css"
import { userimg1 } from "../../Constants"
const OwnerProfile = () => {
    return (
        <>
            <OwnerHeader />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <OwnerSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title">
                                            <h3>My profile</h3>
                                        </div>
                                        <div class="tab_data_start profile-tab  with-border pb-5 mt-3">
                                            <div class="row mx-0 mt-3 pb-lg-3">
                                                <div class="col-xl-9 col-lg-12 order_lg_1">
                                                    <div class="profile-data">
                                                        <div class="users-data-start">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>First Name</span>
                                                                        <span>James</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>Last Name</span>
                                                                        <span>Anderson</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>Phone</span>
                                                                        <span>+1 (322) 422 654</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-8">
                                                                    <div class="for-user-data">
                                                                        <span>Primary Email</span>
                                                                        <span>Jamesanderson@gmail.com</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>Password</span>
                                                                        <span class="password">************ <Link to="/ownerchangepassword" class="editprofilebtn change-password">Change Password</Link></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="accordion" id="accordionExample">
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header" id="headingThree">
                                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                    More Details
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                                <div class="accordion-body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>Gender</span>
                                                                                                <span>Male</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>Date Of Birth</span>
                                                                                                <span>20 October 2023</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data ps-lg-3">
                                                                                                <span>Age</span>
                                                                                                <span>28</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>Drivers License</span>
                                                                                                <span>48987752526</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-12">
                                                                                            <div class="for-user-data">
                                                                                                <span>Primary Address</span>
                                                                                                <span>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis p</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-12">
                                                                                            <div class="for-user-data">
                                                                                                <span>Secondary Address</span>
                                                                                                <span>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis p</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>City</span>
                                                                                                <span>Newyork</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>State</span>
                                                                                                <span>Chicago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>Zipcode</span>
                                                                                                <span>422 654</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <div class="for-user-data">
                                                                                                <span>Country</span>
                                                                                                <span>United States</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-12">
                                                    <div class="edit-profile-btn pe-lg-5 d-flex flex-column align-items-end">
                                                        <Link to="/ownereditprofile" class="btn primary-btn change-password-btn">Edit Profile <img src={ArrowRight} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-profile-tab hide mt-3">
                                            <div class="title">
                                                <h3>Edit Profile</h3>
                                            </div>
                                            <div class="col-lg-10 col-md-12">
                                                <div class="form_start">
                                                    <form method="POST">
                                                        <div class="row">
                                                            <div class="col-md-6 mt-3">
                                                                <label for="FirstName">First Name</label>
                                                                <input type="text" name="Email" id="FirstName" placeholder="First Name" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Address">Phone</label>
                                                                <input type="tel" name="Phone" id="Phone" placeholder="Phone" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="LastName">Last Name</label>
                                                                <input type="text" name="LastName" id="LastName" placeholder="Last Name" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="EmailAddress">Email Address</label>
                                                                <input type="email" name="Email" id="EmailAddress" placeholder="Enter here" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex mt-3 align-items-center justify-content-end flex-lg-row flex-column gap-lg-0 gap-4">
                                                            <button class="primary-btn center px-5" type="submit">Update
                                                                Profile</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-profile-password hide mt-3">
                                            <div class="heading">
                                                <h3>Change Password</h3>
                                            </div>
                                            <div class="col-lg-9 col-md-12">
                                                <div class="form_start mt-3">
                                                    <form method="POST">
                                                        <div class="row">
                                                            <div class="col-md-6 me-lg-5 mt-3">
                                                                <label for="OldPassword">Old Password</label>
                                                                <input type="password" name="Email" id="OldPassword" placeholder="First Name" />
                                                            </div>
                                                            <div class="col-md-6 me-lg-5 mt-3">
                                                                <label for="NewPassword">New Password</label>
                                                                <input type="password" name="Email" id="NewPassword" placeholder="Enter Here" />
                                                            </div>
                                                            <div class="col-md-6 me-lg-5 mt-3">
                                                                <label for="ConfirmNewPassword">Confirm New
                                                                    Password</label>
                                                                <input type="password" name="ConfirmNewPassword" id="ConfirmNewPassword" placeholder="Enter Here" />
                                                            </div>

                                                            <div class="col-md-6 me-lg-5 mt-3">
                                                                <div class="the_input w-100 text-end">
                                                                    <button class="primary-btn center px-5" type="submit">Change Password </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerProfile;