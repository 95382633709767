import React from "react";
import Header from "../../components/Header";
import Contacts from "../Contacts/Contacts";
import "../../assets/css/style.css";
import { Link } from 'react-router-dom'
import { ArrowRight,client1, client2, client3, client4, topbannerimg, sicon1, sicon2, sicon3, aboutimg, googleplay, appstore, downloadmob, testimonialclientimg,ArrowRightYellow } from "../../Constants";
import Footer from "../../components/footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Home = () => {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
    };
    let testSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
    return (
        <>
            {/* Header start Here */}
            <Header />
            {/* Header End Here */}
            {/* <!-- banner sec Start Here --> */}
            <div class="top-banner-sec">
                <div class="container">
                    <div class="banner-content">
                        <div class="row">
                            <div class="col-md-6 wow animate__animated animate__fadeInLeftBig">
                                <h3>Welcome To Asset Registry</h3>
                                <h1>Heaven For Authenticators</h1>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna. Tempus iaculis urna id volutpat lacus.</p>

                                <Link to="/" class="btn primary-btn px-4">Learn More <img src={ArrowRight} alt="" /></Link>
                                <div class="col-md-12 trusted-Client wow animate__animated animate__fadeInLeftBig"
                                    data-wow-delay=".2s">
                                    <h4>Trusted Clients</h4>

                                    <Slider {...settings} className="slider">
                                        <div><img class="img-fluid pe-3" src={client1}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client2}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client3}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client4}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client1}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client2}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client3}
                                            alt="Trusted Clients img" /></div>
                                        <div><img class="img-fluid pe-3" src={client4}
                                            alt="Trusted Clients img" /></div>
                                    </Slider>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div class="top-banner-img">
                    <img class="img-fluid" src={topbannerimg} alt="top banner img" />
                </div>

                <div class="socila-icon ">
                    <Link to="javascript:void(0);">Facebook</Link>
                    <Link to="javascript:void(0);">Instagram</Link>
                    <Link to="javascript:void(0);">Twitter</Link>
                </div>
            </div>
            {/* <!-- banner sec End Here -->             */}

            {/* <!-- services sec Start Here --> */}
            <section class="services-sec wow animate__animated animate__fadeInUpBig">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img src={sicon1} alt="icon" />
                                    <h2>Asset registry</h2>
                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                    <Link to="">Learn More <img src={ArrowRightYellow} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img src={sicon2} alt="icon" />
                                    <h2>Asset registry</h2>
                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                    <Link to="">Learn More <img src={ArrowRightYellow} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img src={sicon3} alt="icon" />
                                    <h2>Asset registry</h2>
                                    <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit </p>
                                    <Link to="">Learn More <img src={ArrowRightYellow} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- services sec End Here --> */}

            {/* <!-- About sec Start Here --> */}
            <section class="about-sec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 wow animate__animated animate__fadeInLeftBig">
                            <img class="img-fluid" src={aboutimg} alt="About Img" />
                        </div>

                        <div class="col-md-6 wow animate__animated animate__fadeInRightBig">
                            <div class="ps-lg-5">
                                <h3>Welcome To Asset Registry</h3>
                                <h2 class="large-heading">ABOUT ASSETS AUTHENTICATOR</h2>
                                <p>
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in
                                    fermentum. Ut fauci.<br /><br />
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum.
                                </p>
                                <ul>
                                    <li>Vitae congue eu consequat </li>
                                    <li>Vitae congue eu consequat </li>
                                    <li>Vitae congue eu consequat </li>
                                    <li>Vitae congue eu consequat </li>
                                </ul>
                                <Link to="/aboutus" class="btn primary-btn px-4">Learn More <img src={ArrowRight} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- About sec End Here --> */}

            {/* <!-- download sec Start Here --> */}
            <section class="download-sec">
                <div class="container">
                    <div class="row">

                        <div class="col-md-6 wow animate__animated animate__fadeInLeftBig" data-wow-delay=".2s">
                            <div class="download-sec-content">
                                <h3>Welcome To Asset Registry</h3>
                                <h2 class="large-heading">GET YOUR ASSETS REGISTERED</h2>
                                <p>
                                    Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in
                                    fermentum. Ut fauci.
                                </p>
                                <h4>Available To <spen>Download</spen></h4>
                                <div class="download-btn mt-3">
                                    <a href="javascript:void(0);">
                                        <img class="img-fluid me-3" src={googleplay} alt="download Img" />
                                    </a>
                                    <a href="javascript:void(0);">
                                        <img class="img-fluid" src={appstore} alt="download Img" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 wow animate__animated animate__fadeInRightBig" data-wow-delay=".5s">
                            <img class="img-fluid download-mob" src={downloadmob} alt="download Img" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- download sec End Here --> */}


            {/* <!-- Testimonials sec Start Here --> */}
            <section class="testimonials-sec">
                <div class="container">
                    <div class="inner-bor">
                        <div class="col-md-12 testimonials-bg">

                            <h3>Testimonials</h3>
                            <h2 class="large-heading">WORDS FROM CLIENTS</h2>
                            <Slider {...testSettings} class="testimonials-items">
                                <div class="slide">
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in
                                        fermentum. Ut fauci.Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis
                                        purus
                                        sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                                        justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec
                                        feugiat in fermentum. Ut fauci.
                                    </p>
                                    <div class="testimonialsContent">
                                        <div class="client-img">
                                            <img src={testimonialclientimg} alt="client Img" />
                                        </div>
                                        <div>
                                            <h2>John Doe Mark</h2>
                                            <p>Owner Assets</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="slide">
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in
                                        fermentum. Ut fauci.Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis
                                        purus
                                        sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                                        justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec
                                        feugiat in fermentum. Ut fauci.
                                    </p>
                                    <div class="testimonialsContent">
                                        <div class="client-img">
                                            <img src={testimonialclientimg} alt="client Img" />
                                        </div>
                                        <div>
                                            <h2>John Doe Mark</h2>
                                            <p>Owner Assets</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="slide">
                                    <p>
                                        Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                        Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                        magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec feugiat in
                                        fermentum. Ut fauci.Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis
                                        purus
                                        sit amet. Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                                        justo eget magna. Tempus iacmst quisque sagittis purus sit amet. Adipiscing tristique risus nec
                                        feugiat in fermentum. Ut fauci.
                                    </p>
                                    <div class="testimonialsContent">
                                        <div class="client-img">
                                            <img src={testimonialclientimg} alt="client Img" />
                                        </div>
                                        <div>
                                            <h2>John Doe Mark</h2>
                                            <p>Owner Assets</p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>


                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Testimonials sec End Here --> */}
            <Footer />
        </>
    )
}

export default Home;