import React, { useEffect } from "react";
import { logo,ArrowRight } from "../Constants";
import { Link } from "react-router-dom";
import { userimg1 } from "../Constants";
const AuthenticatorHeader = () => {
    return (
        <>
            <header>
				<div class="sec-header">
					<div class="container">
						<div class="sec-header-content">
							<Link to="/mailto:support@example.com"> <svg xmlns="http://www.w3.org/2000/svg" width="14.18" height="9.77" viewBox="0 0 14.18 9.77">
								<g id="Group_8693" data-name="Group 8693" transform="translate(0.4 0.4)">
									<path id="Path_15" data-name="Path 15" d="M478.717,391.339V385.2a.371.371,0,0,1,.058.026l5.429,3.621a2.014,2.014,0,0,0,2.392.007q2.683-1.787,5.364-3.576c.038-.025.077-.049.133-.084v.107q0,2.926,0,5.851a1.338,1.338,0,0,1-.039.322,1.232,1.232,0,0,1-1.259.932h-5.342q-2.717,0-5.433,0a1.288,1.288,0,0,1-.927-.351A1.384,1.384,0,0,1,478.717,391.339Z" transform="translate(-478.717 -383.436)" fill="none" stroke="#fff" stroke-width="0.8" />
									<path id="Path_16" data-name="Path 16" d="M478.717,305.715c.022-.075.04-.15.065-.224a1.236,1.236,0,0,1,1.184-.842c.257,0,.514,0,.771,0q5.022,0,10.044,0a1.263,1.263,0,0,1,.992.41,1.186,1.186,0,0,1-.207,1.8c-.813.553-1.635,1.093-2.453,1.639l-2.921,1.948a1.245,1.245,0,0,1-1.573,0c-1.716-1.144-3.429-2.293-5.15-3.428a1.513,1.513,0,0,1-.752-.964Z" transform="translate(-478.717 -304.648)" fill="none" stroke="#fff" stroke-width="0.8" />
								</g>
							</svg>
								support@example.com</Link>
							<Link to="/tel:123456789"><i class="fa-solid fa-phone"></i> +123 456 789</Link>
						</div>
					</div>
				</div>
				<nav class="navbar navbar-expand-lg navbar-light ">
					<div class="container">
						<Link class="navbar-brand" to="/">
							<img src={logo} alt="logo" />
						</Link>
						<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse" id="navbarSupportedContent">
							<ul class="navbar-nav m-auto">
								<li class="nav-item">
									<Link class="nav-link" aria-current="page" to="/">Home</Link>
								</li>
								<li class="nav-item">
									<Link class="nav-link" to="/aboutus">About Us</Link>
								</li>
								<li class="nav-item">
									<Link class="nav-link" to="/services">Services</Link>
								</li>
								<li class="nav-item">
									<Link class="nav-link" to="/HowWeAuthenticate">How We Authenticate</Link>
								</li>
								<li class="nav-item">
									<Link class="nav-link" to="/howitworks">How It Works</Link>
								</li>
								<li class="nav-item">
									<Link class="nav-link" to="/contact">Contact Us</Link>
								</li>
								<li class="nav-item">
								</li>
							</ul>
							<form class="btn-flex">
								<Link to="/" className="profile-link-header">
                                    <div className="text">
                                        <span className="light-text">WELCOME BACK</span>
                                        <span className="bold-text">JOHN DOE</span>
                                    </div>
                                    <figure className="user-profile-img">
                                        <img src={ userimg1 } alt="" />
                                    </figure>
                                </Link>
							</form>
						</div>
					</div>
				</nav>
			</header>
        </>
    )
}

export default AuthenticatorHeader;