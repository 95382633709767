import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import "../../assets/css/profile.css"
import { verifiedimage,ArrowRight } from "../../Constants"
import { Link } from 'react-router-dom'

const AssetProgress = () => {
    return (
        <>
            <Header />
                <section className="asset-final-verification in-progress">
                    <div className="container">
                        <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 mx-auto px-xl-3">
                            <div className="asset-box">
                                <div className="top-text px-xl-3">
                                    <figure>
                                        <img src={verifiedimage} alt="" />
                                    </figure>
                                    <h2 className="large-heading px-xl-4">Your Verification is in process</h2>
                                    <p className="px-xl-2">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing tristique risus nec</p>
                                </div>
                                <div className="only-for-verified">
                                    <div className="asset-details">
                                        <div className="row px-xxl-3">
                                            <div className=" col-md-6 ms-auto">
                                                <div className="single-data">
                                                    <span className="question">Asset Type</span>
                                                    <span className="answer">Your answer</span>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 me-auto">
                                                <div className="single-data">
                                                    <span className="question">Owner Name</span>
                                                    <span className="answer">Your answer</span>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 ms-auto">
                                                <div className="single-data">
                                                    <span className="question">Year Purchased</span>
                                                    <span className="answer">Your answer</span>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 me-auto">
                                                <div className="single-data">
                                                    <span className="question">Previous Owner Name</span>
                                                    <span className="answer">Your answer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link className="btn primary-btn mx-auto mt-5" to="/">Back To Assets <img src={ArrowRight} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}
export default AssetProgress;