import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import "../../assets/css/auth.css"
import { assetownerbg, ArrowRight } from "../../Constants"
import { FileUploader } from "react-drag-drop-files"
import { useNavigate } from "react-router-dom"
const UploadAsset = () => {
    const fileTypes = ["docx", "pptx", "pdf"];
    const ImageTypes = ["JPG", "PNG", "GIF"];
    const [file, setFile] = useState(null);
    const [Img, setImg] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };
    const handleImg = (file) => {
        setFile(file);
    };
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/assetpayment'); // navigate to the success page
    }
    // Social Settings Start Here
    return (
        <>
            {/* UploadAsset Start Here */}
            <Header />
            <section class="auth-section uploadasset start-the-auth">
                <div className="container-fluid">
                    <div className="hedadingDiv text-center">
                        <h3>Welcome To Asset Registry</h3>
                        <h2>start authentication</h2>
                        <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu non
                            odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus</p>
                    </div>
                </div>
                <div class="container px-0">
                    <div class="auth-start mt-lg-5 mt-md-4">
                        <div class="row mx-0">
                            <div class="col-md-7 ps-md-0">

                                <div class="from-start h-100 d-flex flex-column justify-content-center position-relative">
                                    <form onSubmit={handleSubmit}>
                                        <h4 className="asset-dtail-hading">Asset details</h4>
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="assettype">Asset Type *</label>
                                                    <input type="text" name="assettype" id="assettype" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="YearPurhcased">Year Purhcased</label>
                                                    <input type="text" name="YearPurhcased" id="YearPurhcased" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="OwnerName">Owner Name</label>
                                                    <input type="text" name="OwnerName" id="OwnerName" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="PreviousOwnerName">Previous Owner Name</label>
                                                    <input type="text" name="PreviousOwnerName" id="PreviousOwnerName" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="PurchasedPrice">Purchased Price (Optional)</label>
                                                    <input type="text" name="PurchasedPrice" id="PurchasedPrice" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="CurrentValue">Current Value (Optional)</label>
                                                    <input type="text" name="CurrentValue" id="CurrentValue" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group">
                                                    <label htmlFor="MortgageLoanBalance">Mortgage Loan Balance</label>
                                                    <input type="text" name="MortgageLoanBalance" id="MortgageLoanBalance" class="form-control" placeholder="Your answer" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="DriversLicense">Attach Documents</label>
                                                {/* <FileUploader
                                                        handleChange={handleChange}
                                                        name="file"
                                                        id="DriversLicense"
                                                        types={fileTypes}
                                                    /> */}
                                                <label htmlFor="toupload" className="sc-bcXHqe hjYtIJ  ">

                                                    <input type="file" name="" id="toupload" hidden />
                                                    <div class="sc-dkrFOg dmajdp"><span class="sc-hLBbgP bXgVCj"><span>Upload / Drag a file here</span></span><span title="types: JPG,PNG,GIF" class="file-types">JPG,PNG,GIF</span></div>
                                                </label>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="DriversLicense">Upload Images</label>
                                                {/* <FileUploader
                                                        handleChange={handleImg}
                                                        name="file"
                                                        id="DriversLicense"
                                                        types={ImageTypes}
                                                    /> */}
                                                <label htmlFor="touploada" className="sc-bcXHqe hjYtIJ  ">

                                                    <input type="file" name="" id="touploada" hidden />
                                                    <div class="sc-dkrFOg dmajdp"><span class="sc-hLBbgP bXgVCj"><span>Upload / Drag a file here</span></span><span title="types: JPG,PNG,GIF" class="file-types">JPG,PNG,GIF</span></div>
                                                </label>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group textarea">
                                                    <label htmlFor="Description">Description</label>
                                                    <textarea type="text" name="Description" id="Description" class="form-control" placeholder="Your answer" ></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div className="for-group textarea">
                                                    <label htmlFor="AboutMe">About Me</label>
                                                    <textarea type="text" name="AboutMe" id="AboutMe" class="form-control" placeholder="Your answer" ></textarea>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="d-flex justify-content-end  pb-3 mt-4">
                                            <button class="btn primary-btn">Proceed To Payment <img src={ArrowRight} alt="" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-5 px-md-0 ps-lg-5">
                                <figure className="img-wrapper">
                                    <img src={assetownerbg} className="img-fluid w-100" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default UploadAsset;