import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import { Link } from 'react-router-dom'
import { ArrowRight  } from '../../Constants'
import "../../assets/css/contact.css"
const Contacts = () => {
    return (
        <>
        <Header />
            {/* <!-- contact sec Start Here --> */}
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>contact us</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- contact sec End Here --> */}
            <div calss="container">
                <div className="contact-content">
                    <div className="row">
                        <div className="col-lg-4 mb-3">
                            <div className="contact-div">
                                <div className="contact-icon"><i className="fa-solid fa-phone"></i></div>
                                <div className="contact-text">
                                    <h4>Call Us :</h4>
                                    <a href="tel:123456789">Telephone : 123456789</a>
                                    <a href="tel:123456789">Mobile : 123456789</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="contact-div">
                                <div className="contact-icon"><i className="fa-solid fa-envelope"></i></div>
                                <div className="contact-text">
                                    <h4>Email :</h4>
                                    <a href="mailto:sample@example.com">sample@example.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="contact-div">
                                <div className="contact-icon"><i className="fa-solid fa-location-dot"></i></div>
                                <div className="contact-text">
                                    <h4>Address :</h4>
                                    <a href="">Vitae congue eu conseq uat ac felis donec</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <form className="mt-3 contact-form">
                        <div className="row">
                            <div className="mb-3 col-md-6">
                                <input type="text" className="form-control" placeholder="First Name" required="" />
                            </div>
                            <div className="mb-3 col-md-6">
                                <input type="text" className="form-control" placeholder="Last Name" required="" />
                            </div>
                            <div className="mb-3 col-md-6">
                                <input type="text" className="form-control" placeholder="Email Address" required="" />
                            </div>
                            <div className="mb-3 col-md-6">
                                <input type="number" className="form-control" placeholder="Contact Number" required="" />
                            </div>

                            <div className="mb-3">
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message here..."
                                    required=""></textarea>
                            </div>
                            <div className="mb-3 pt-3">
                                <button className="btn primary-btn ms-auto  change-password-btn" type="submit">Submit <img src={ArrowRight} alt="" /></button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <section className="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12101.225545940439!2d-74.06200986044922!3d40.68924940000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25090129c363d%3A0x40c6a5770d25022b!2sStatue%20of%20Liberty!5e0!3m2!1sen!2s!4v1679916791045!5m2!1sen!2s" width="100%" height="390"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
            {/* <!-- Body Content End Here --> */}
            <Footer />
        </>
    )
}
export default Contacts;