import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
const OwnerAssetsList = () => {
    let assettype = "Your Answer";
    let ownername = "John Doe Smith";
    let purchaseyear = "2022"
    const assets = [
        {
            assettype: assettype,
            ownername: ownername,
            purchaseyear: purchaseyear,
            verification: "Verified",
            detaillink: "/ownerverifiedasset"
        },
        {
            assettype: assettype,
            ownername: ownername,
            purchaseyear: purchaseyear,
            verification: "In Progress",
            detaillink: "/ownerverificationprogress"
        },
        {
            assettype: assettype,
            ownername: ownername,
            purchaseyear: purchaseyear,
            verification: "Not Verified",
            detaillink: "/ownernotverifiedasset"
        },
        {
            assettype: assettype,
            ownername: ownername,
            purchaseyear: purchaseyear,
            verification: "Verified",
            detaillink: "/ownerverifiedasset"
        }
    ]
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <OwnerSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        {/* <div class="title">
                                            <h3>Assets for Verification</h3>
                                        </div> */}
                                        <div class="assets-data">
                                            <div class="assets-table">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="assets-type">Asset Type</th>
                                                            <th class="owner-name">Owner Name</th>
                                                            <th class="year-purchase">Year Purchase</th>
                                                            <th class="status">Status</th>
                                                            <th class="viewdetails"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assets.map((asset, index) => (
                                                            <tr key={index}>
                                                                <td>{asset.assettype}</td>
                                                                <td>{asset.ownername}</td>
                                                                <td>{asset.purchaseyear}</td>
                                                                <td><span className={asset.verification.replace(/\s+/g, '').toLowerCase()}>{asset.verification}</span></td>
                                                                <td className="text-end"><Link className="details btn primary-btn" to={asset.detaillink}>View Details <img src={ArrowRight} alt="" /></Link></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <ul class="pagination">
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                <li class="pagi"><a href="#">1</a></li>
                                                <li class="pagi"><a class="active" href="#">2</a></li>
                                                <li class="pagi"><a href="#">3</a></li>
                                                <li class="pagi"><a href="#">4</a></li>
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerAssetsList;