import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import { servicesicon } from '../../Constants'
import "../../assets/css/services.css"
const Services = () => {
    return (
        <>
        <Header />
            {/* <!-- services sec Start Here --> */}
            <div class="services">
                <div class="container">
                    <div class="banner-content">
                        <div class="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Our Services</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>

                        <ul class="services-list">
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon" />
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon" />
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon"/>
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon" />
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon" />
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                            <li>
                                <div class="services-icon">
                                    <img src={ servicesicon } alt="icon" />
                                </div>
                                <h2>Asset registry</h2>
                                <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet.
                                    Adipiscing tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae justo eget
                                    magna.</p>
                            </li>
                        </ul>

                    </div>


                </div>

            </div>
            <Footer />
            {/* <!-- services sec End Here --> */}
        </>
    )
}
export default Services;