import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import "../../assets/css/auth.css";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { ArrowRight, logo } from "../../Constants";
const SignUp = () => {
	const fileTypes = ["JPG", "PNG", "GIF"];
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/myprofile"); // navigate to the success page
	};
	return (
		<>
			{/* SignUp Start Here */}
			<section class="auth-section auth-only">
				<Header />
				<div class="container px-0">
					<div class="auth-start">
						<div className="logo-box">
							<Link to="/">
								<figure>
									<img src={logo} alt="site-logo" />
								</figure>
							</Link>
						</div>
						<div class="row justify-content-center">
							<div class="col-md-8 ps-md-0">
								<div class="from-start h-100 d-flex flex-column justify-content-center position-relative pt-2 pb-4">
									<div class="banner-content pt-0 pb-3">
										<div class="hedadingDiv">
											<h3>Welcome To Asset Registry</h3>
											<h1>Join AS Authenticator</h1>
											<p class="w-100 mb-0 pb-2">
												Vitae congue eu consequat ac felis donec. Platea
												dictumst quisque sagittis purus sit amet. Adipiscing
												tristique risus nec feugiat in fermentum. Ut fauci.Ut eu
												sem integer vitae justo eget magna. Tempus iaculis urna
												id volutpat lacus.
											</p>
										</div>
									</div>
									<form onSubmit={handleSubmit}>
										<div class="row">
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Full Name"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Email Address"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Contact Number"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Company Name"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Company Address"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Company Corporation EFIN Number"
												/>
											</div>
											<div className="col-md-12 mb-3">
												<label htmlFor="DriversLicense">Drivers License</label>
												{/* <FileUploader
                                                        handleChange={handleChange}
                                                        name="file"
                                                        id="DriversLicense"
                                                        types={fileTypes}
                                                    /> */}
												<label
													htmlFor="toupload"
													className="sc-bcXHqe hjYtIJ  "
												>
													<input type="file" name="" id="toupload" hidden />
													<div class="sc-dkrFOg dmajdp">
														<span class="sc-hLBbgP bXgVCj">
															<span>Upload / Drag a file here</span>
														</span>
														<span title="types: JPG,PNG,GIF" class="file-types">
															JPG,PNG,GIF
														</span>
													</div>
												</label>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="password"
													class="form-control"
													placeholder="Password"
												/>
											</div>
											<div class="col-md-6 mb-3">
												<input
													type="password"
													class="form-control"
													placeholder="Confirm Password"
												/>
											</div>
										</div>
										<p class="small-text">
											Your password must be up to 12 digits, characters, symbols
										</p>
										<div class="d-flex justify-content-end mb-4 pb-3  mb-1">
											<button class="btn primary-btn px-4">
												Join Now <img src={ArrowRight} alt="" />
											</button>
										</div>
										<div class="terms-line w-100 text-center pt-3 position-absolute bottom-0">
											<p>
												By Joining as authenticator you agree to our{" "}
												<Link to="/terms-conditions">Terms & conditions</Link>
											</p>
										</div>
									</form>
								</div>
							</div>
							{/* <div class="col-md-5 pe-md-0">
                                <div class="auth-background">

                                </div>
                            </div> */}
						</div>
					</div>
				</div>
				<Footer />
			</section>
		</>
	);
};
export default SignUp;
