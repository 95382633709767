import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { pdficon } from "../../Constants"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
import Calendar from 'react-calendar';
const CheckVerification = () => {
    const [value, onChange] = useState(new Date());
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="profile-section">
                <div className="container">
                    <div className="profile-start">
                        <div className="row">
                            <OwnerSidebar />
                            <div className="col-lg-9 col-md-12">
                                <div className="profile_body h-100">
                                    <div className="single_tab ">
                                        <div className="verification-details">
                                            <div className="row mt-3">
                                                <div className="col-xl-4 col-lg-5 col-md-12">
                                                    <div className="chec-verification">
                                                        <h4 className="large mb-4">Check Asset verification</h4>
                                                        <div className="from-group mb-3">
                                                            <label htmlFor="">Enter Your Verification Number</label>
                                                            <input type="text" className="form-control" name="" id="" placeholder="Your answer" />
                                                        </div>
                                                        <div className="d-flex">
                                                            <Link className="btn primary-btn ms-auto change-password-btn" to="">Submit <img src={ArrowRight} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 ps-xl-0 ps-lg-3 order-xl-0 order-lg-4"></div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-7 ms-xxl-0 me-xxl-auto ms-lg-auto  me-lg-0  mx-lg-auto ">
                                                    <div className="verification-details">
                                                        <h4 className="large mb-4">Asset details</h4>
                                                        <div className="verification-details-box pb-4 complete-border">
                                                            <div class="heading"><h3 class="bold-heading">Asset Detail</h3></div>
                                                            <div className="asset-details">
                                                                <div className="row">
                                                                    <div class="col-md-6 px-0">
                                                                        <div class="single-data px-2">
                                                                            <span class="question">Asset Type</span>
                                                                            <span class="answer">Your answer</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 px-0">
                                                                        <div class="single-data px-2">
                                                                            <span class="question">Owner Name</span>
                                                                            <span class="answer">Your answer</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 px-0">
                                                                        <div class="single-data px-2">
                                                                            <span class="question">Year Purchased</span>
                                                                            <span class="answer">Your answer</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 px-0">
                                                                        <div class="single-data px-2">
                                                                            <span class="question">Previous Owner Name</span>
                                                                            <span class="answer">Your answer</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default CheckVerification;