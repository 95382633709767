import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import { Link } from 'react-router-dom'
import "../../assets/css/blogs.css"
import { blog1, blog3, blog2, blog4, blog6, blog7, blog8, blog9, asseticon, dateicon,ArrowRight } from '../../Constants'
const Blogs = () => {
    const blogTitle = "GET YOUR ASSETS REGISTERED";
    const blogDescription = "Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit amet. Adipiscing";
    const blogsdata = [
        {
            id: 1,
            title: blogTitle,
            description: blogDescription,
            img: blog1
        },
        {
            id: 2,
            title: blogTitle,
            description: blogDescription,
            img: blog2
        },
        {
            id: 3,
            title: blogTitle,
            description: blogDescription,
            img: blog3
        },
        {
            id: 4,
            title: blogTitle,
            description: blogDescription,
            img: blog4
        },
        {
            id: 5,
            title: blogTitle,
            description: blogDescription,
            img: blog1
        },
        {
            id: 6,
            title: blogTitle,
            description: blogDescription,
            img: blog6
        }, {
            id: 7,
            title: blogTitle,
            description: blogDescription,
            img: blog7
        },
        {
            id: 8,
            title: blogTitle,
            description: blogDescription,
            img: blog8
        },
        {
            id: 9,
            title: blogTitle,
            description: blogDescription,
            img: blog9
        }
    ]
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Our Blogs</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blogs-section">
                <div className="container">
                    <div className="blogs-list">

                        <div className="row">
                            {
                                blogsdata.map((blogs, index) => {
                                    return (
                                        <div className="col-lg-4" key={index}>
                                            <div className="sinlge-blog">
                                                <figure>
                                                    <img src={blogs.img} alt="" className="img-fluid w-100" />
                                                </figure>
                                                <div className="ds-box-total">
                                                    <div className="desciprtion-box">
                                                        <div className="about-blog">
                                                            <div className="single-data">
                                                                <img src={dateicon} alt="" />
                                                                <p className='light-text mb-0'>13 January 2023</p>
                                                            </div>
                                                            <div className="single-data">
                                                                <img src={asseticon} alt="" />
                                                                <p className='light-text mb-0'>Asset Registry</p>
                                                            </div>
                                                        </div>
                                                        <div className="box-details">
                                                            <h3 className="large-heading">{blogs.title}</h3>
                                                            <p>{blogs.description}</p>
                                                        </div>
                                                        <div className="view-details">
                                                            <Link className="btn primary-btn px-4" to="/blogdetails">Learn More <img src={ArrowRight} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Blogs;