import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { notificationimg1,notificationimg2,notificationimg3,ArrowRight } from "../../Constants"
import { Link } from 'react-router-dom'
const OwnerNotifications = () => {
    const notifications = [
        {
            heading: "Your Asset has been Verified",
            time: "15 January 2023  |  8:00 AM",
            image: notificationimg1
        },
        {
            heading: "Meeting has been scheduled with authenticator",
            time: "15 January 2023  |  8:00 AM",
            image: notificationimg2
        },
        {
            heading: "New Message Recieved",
            time: "15 January 2023  |  8:00 AM",
            image: notificationimg3

        },
        {
            heading: "Meeting has been scheduled with authenticator",
            time: "15 January 2023  |  8:00 AM",
            image: notificationimg2
        },
        {
            heading: "New Message Recieved",
            time: "15 January 2023  |  8:00 AM",
            image: notificationimg3
        }
    ]
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="profile-section owner-notifications">
                <div className="container">
                    <div className="profile-start">
                        <div className="row">
                            <OwnerSidebar />
                            <div className="col-lg-9 col-md-12">
                                <div className="profile_body h-100">
                                    <div className="single_tab ">
                                        <div className="title">
                                            <h3>Notifications</h3>
                                        </div>
                                        <div className="notification-list">
                                            <div className="row">
                                            {notifications.map((notification, index) => (
                                                <div className="col-md-12 mt-3">
                                                    <div className="single-notification">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="notification-data">
                                                                    <figure className="notification-image">
                                                                        <img src={notification.image} alt="" />
                                                                    </figure>
                                                                    <div className="notifcation-data">
                                                                        <span className="question">{notification.heading}</span>
                                                                        <span className="answer">{notification.time}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="show-details text-end pe-lg-2">
                                                                    <Link to="" className="btn primary-btn">View Details <img src={ArrowRight} alt="" /></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerNotifications;