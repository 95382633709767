import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/Header";
import {
  logo,
  client1,
  client2,
  client3,
  client4,
  foundersimg1,
  foundersimg2,
  testimonialclientimg,
  videoimg,
  IconVideo,
} from "../../Constants";
import "../../assets/css/about.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const AboutUs = () => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  };
  let testSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <Header />

      {/* <!-- inner banner sec Start Here --> */}
      <div className="about">
        <div className="container">
          <div className="banner-content">
            <div className="col-md=12">
              <h3>Welcome To Asset Registry</h3>
              <h1>Asset Registry Objective</h1>
            </div>
            <div className="row">
              <div className="col-md-7 wow animate__animated animate__fadeInLeftBig">
                <div className="about-logo">
                  {/* <div>
                                        <img src={logo} alt="logo" />
                                    </div> */}
                  <div>
                    <p>
                      Elementum sagittis vitae et leo duis ut. Euismod quis
                      viverra nibh cras pulvinar mattis nunc sed. Mattis
                      vulputate enim nulla aliquet porttitor lacus. Vitae nunc
                      sed velit dignissim sodales ut eu. Purus non enim praesent
                      elementum facilisis leo. Elementum facilisis leo vel
                      fringilla est. Elementum sagittis vitae et leo duis ut
                      diam. Auctor neque vitae tempus quam pellentesque nec nam.
                      Arcu risus quis varius quam quisque id.
                    </p>
                  </div>
                </div>
                <p>
                  Vitae congue eu consequat ac felis donec. Platea dictumst
                  quisque sagittis purus sit amet. Adipiscing tristique risus
                  nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                  justo eget magna. Tempus iaculis urna id volutpat lacus.
                </p>
              </div>
              <div className="col-md-5 wow animate__animated animate__fadeInRightBig">
                <ul>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                  <li>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- inner banner sec End Here --> */}
      <section className="sotry-sec">
        <div className="container">
          <div className="hedadingDiv text-center">
            <h3>Welcome To Asset Registry</h3>
            <h2>Our Story</h2>
            <p>
              Auctor elit sed vulputate mi sit amet mauris commodo quis.
              Ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia.
              Etiam dignissim diam quis enim lobortis scelerisque. Luctus
              venenatis lectus
            </p>
          </div>
          <p className=" mt-3">
            Elementum sagittis vitae et leo duis ut. Euismod quis viverra nibh
            cras pulvinar mattis nunc sed. Mattis vulputate enim nulla aliquet
            porttitor lacus. Vitae nunc sed velit dignissim sodales ut eu. Purus
            non enim praesent elementum facilisis leo. Elementum facilisis leo
            vel fringilla est. Elementum sagittis vitae et leo duis ut diam.
            Auctor neque vitae tempus quam pellentesque nec nam. Arcu risus quis
            varius quam quisque id. Posuere ac ut consequat semper viverra nam
            libero justo laoreet. Amet nulla facilisi morbi tempus.t porttitor
            lacus. Vitae nunc sed velit dignissim sodales ut eu. Purus non enim
            praesent elementum facilisis leo. Elementum facilisis leo vel
            fringilla est. Elementum sagittis vitae et leo duis ut diam. Auctor
            neque vitae tempus quam pellentesque nec nam. Arcu risus quis varius
            quam quisque id. Posuere ac ut consequat semper viverra nam libero
            justo laoreet. Amet nulla facilisi morbi tempus.
          </p>
          {/* <div className="video-about">
            <video className="w-100" poster={videoimg}>
              <source src="movie.mp4" type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video>
            <img src={IconVideo} className="play-icon" alt="" />
          </div> */}
        </div>
      </section>
      {/* <!-- Our Story sec End Here --> */}

      {/* <!-- Our clients sec Start Here --> */}
      <section className="clients-sec">
        <div className="container">
          <div
            className="row wow animate__animated animate__fadeInLeftBig"
            data-wow-delay=".2s"
          >
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12">
              <div className="hedadingDiv">
                <h3>Welcome To Asset Registry</h3>
                <h2>Our Clients</h2>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-12">
              <div className="col-md-12 trusted-Client">
                <Slider {...settings} className="slider">
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client1}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client2}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client3}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client4}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client1}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client2}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client3}
                      alt="Trusted Clients img"
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid pe-3"
                      src={client4}
                      alt="Trusted Clients img"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our clients sec End Here --> */}

      {/* <!-- Our founders sec Start Here --> */}
      <section className="founders-sec">
        <div className="container">
          <div className="hedadingDiv text-center">
            <h3>Welcome To Asset Registry</h3>
            <h2>OUR founders</h2>
            <p>
              Auctor elit sed vulputate mi sit amet mauris commodo quis.
              Ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia.
              Etiam dignissim diam quis enim lobortis scelerisque. Luctus
              venenatis lectus
            </p>
          </div>
          <div className="founders-content">
            <div className="row align-items-center">
              <div className="col-md-3">
                <img className="img-fluid w-100" src={foundersimg1} alt="img" />
              </div>
              <div className="col-md-9">
                <h4>MARK BROWN</h4>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est. Elementum
                  sagittis vitae et leo duis ut diam. Auctor neque vitae tempus
                  quam pellentesque nec nam. Arcu risus quis varius quam quisque
                  id. Posuere ac ut consequat semper viverra nam libero justo
                  laoreet. Amet nulla facilisi morbi tempus.t porttitor lacus.
                  Vitae nunc sed velit
                </p>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est.
                </p>
              </div>
            </div>
          </div>

          <div className="founders-content">
            <div className="row align-items-center">
              <div className="col-md-3">
                <img
                  className="img-fluid  w-100"
                  src={foundersimg2}
                  alt="img"
                />
              </div>
              <div className="col-md-9">
                <h4>MARK BROWN</h4>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est. Elementum
                  sagittis vitae et leo duis ut diam. Auctor neque vitae tempus
                  quam pellentesque nec nam. Arcu risus quis varius quam quisque
                  id. Posuere ac ut consequat semper viverra nam libero justo
                  laoreet. Amet nulla facilisi morbi tempus.t porttitor lacus.
                  Vitae nunc sed velit
                </p>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est.
                </p>
              </div>
            </div>
          </div>

          <div className="founders-content">
            <div className="row align-items-center">
              <div className="col-md-3">
                <img
                  className="img-fluid  w-100"
                  src={foundersimg1}
                  alt="img"
                />
              </div>
              <div className="col-md-9">
                <h4>MARK BROWN</h4>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est. Elementum
                  sagittis vitae et leo duis ut diam. Auctor neque vitae tempus
                  quam pellentesque nec nam. Arcu risus quis varius quam quisque
                  id. Posuere ac ut consequat semper viverra nam libero justo
                  laoreet. Amet nulla facilisi morbi tempus.t porttitor lacus.
                  Vitae nunc sed velit
                </p>
                <p>
                  Elementum sagittis vitae et leo duis ut. Euismod quis viverra
                  nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
                  nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
                  sodales ut eu. Purus non enim praesent elementum facilisis
                  leo. Elementum facilisis leo vel fringilla est.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our founders sec End Here --> */}

      {/* <!-- Testimonials sec Start Here --> */}
      <section className="testimonials-sec">
        <div className="container">
          <div class="inner-bor">
            <div className="col-md-12 testimonials-bg">
              <h3>Testimonials</h3>
              <h2>WORDS FROM CLIENTS</h2>
              <Slider {...testSettings} class="testimonials-items">
                <div className="slide">
                  <p>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque sagittis purus sit amet. Adipiscing tristique risus
                    nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                    justo eget magna. Tempus iacmst quisque sagittis purus sit
                    amet. Adipiscing tristique risus nec feugiat in fermentum.
                    Ut fauci.Vitae congue eu consequat ac felis donec. Platea
                    dictumst quisque sagittis purus sit amet. Adipiscing
                    tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem
                    integer vitae justo eget magna. Tempus iacmst quisque
                    sagittis purus sit amet. Adipiscing tristique risus nec
                    feugiat in fermentum. Ut fauci.
                  </p>
                  <div className="testimonialsContent">
                    <div className="client-img">
                      <img src={testimonialclientimg} alt="client Img" />
                    </div>
                    <div>
                      <h2>John Doe Mark</h2>
                      <p>Owner Assets</p>
                    </div>
                  </div>
                </div>

                <div className="slide">
                  <p>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque sagittis purus sit amet. Adipiscing tristique risus
                    nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                    justo eget magna. Tempus iacmst quisque sagittis purus sit
                    amet. Adipiscing tristique risus nec feugiat in fermentum.
                    Ut fauci.Vitae congue eu consequat ac felis donec. Platea
                    dictumst quisque sagittis purus sit amet. Adipiscing
                    tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem
                    integer vitae justo eget magna. Tempus iacmst quisque
                    sagittis purus sit amet. Adipiscing tristique risus nec
                    feugiat in fermentum. Ut fauci.
                  </p>
                  <div className="testimonialsContent">
                    <div className="client-img">
                      <img src={testimonialclientimg} alt="client Img" />
                    </div>
                    <div>
                      <h2>John Doe Mark</h2>
                      <p>Owner Assets</p>
                    </div>
                  </div>
                </div>

                <div className="slide">
                  <p>
                    Vitae congue eu consequat ac felis donec. Platea dictumst
                    quisque sagittis purus sit amet. Adipiscing tristique risus
                    nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
                    justo eget magna. Tempus iacmst quisque sagittis purus sit
                    amet. Adipiscing tristique risus nec feugiat in fermentum.
                    Ut fauci.Vitae congue eu consequat ac felis donec. Platea
                    dictumst quisque sagittis purus sit amet. Adipiscing
                    tristique risus nec feugiat in fermentum. Ut fauci.Ut eu sem
                    integer vitae justo eget magna. Tempus iacmst quisque
                    sagittis purus sit amet. Adipiscing tristique risus nec
                    feugiat in fermentum. Ut fauci.
                  </p>
                  <div className="testimonialsContent">
                    <div className="client-img">
                      <img src={testimonialclientimg} alt="client Img" />
                    </div>
                    <div>
                      <h2>John Doe Mark</h2>
                      <p>Owner Assets</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonials sec End Here --> */}

      {/* <!-- Our Story sec Start Here --> */}

      <Footer />
    </>
  );
};
export default AboutUs;
