import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { Link } from 'react-router-dom'
const OwnerMeetingsSchedule = () => {
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <OwnerSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title d-flex flex-column flex-lg-row justify-content-lg-between gap-2">
                                            <h3 className="mb-4">Meeting With authenticator</h3>
                                        </div>
                                        <div class="assets-data">
                                            <div className="row">
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4 className="">Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 mb-4">
                                                    <div className="meeting-details">
                                                        <h4>Meeting Details</h4>
                                                        <div className="yellow-ribon">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-calender"></i>
                                                                        <div className="data">
                                                                            <span className="question">Date</span>
                                                                            <span className="answer">22 January 2023</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="single-data">
                                                                        <i className="fa fa-time"></i>
                                                                        <div className="data">
                                                                            <span className="question">Time</span>
                                                                            <span className="answer">3:30 AM</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="notification-details">
                                                            <span className="question">Additional Note</span>
                                                            <span className="answer">Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="pagination">
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
                                                <li class="pagi"><a href="#">1</a></li>
                                                <li class="pagi"><a class="active" href="#">2</a></li>
                                                <li class="pagi"><a href="#">3</a></li>
                                                <li class="pagi"><a href="#">4</a></li>
                                                <li class="arrow"><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerMeetingsSchedule;