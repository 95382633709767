import React, { useState } from "react"
// import Header from "../../components/Header"
import Footer from "../../components/footer"
import UserSidebar from "../../components/UserSidebar"
import { Link } from 'react-router-dom'
import { ArrowRight } from "../../Constants"
import "../../assets/css/profile.css"
import AuthenticatorHeader from "../../components/AuthenticatorHeader"
const MyProfile = () => {
    return (
        <>
            <AuthenticatorHeader />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Authenticator</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <UserSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title">
                                            <h3>My profile</h3>
                                        </div>
                                        <div class="tab_data_start profile-tab  with-border pb-5 mt-3 col-xl-11">
                                            <div class="row mx-0 mt-3 pb-lg-3">
                                                <div class="col-xl-9 col-lg-12 order_lg_1">
                                                    <div class="profile-data">
                                                        <div class="users-data-start">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>First Name</span>
                                                                        <span>James</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>Last Name</span>
                                                                        <span>Anderson</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="for-user-data">
                                                                        <span>Phone</span>
                                                                        <span>+1 (322) 422 654</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="for-user-data">
                                                                        <span>Address</span>
                                                                        <span>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis p</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-8 col-lg-7 col-md-7">
                                                                    <div class="for-user-data">
                                                                        <span>Primary Email</span>
                                                                        <span>Jamesanderson@gmail.com</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-4 col-lg-5 col-md-5">
                                                                    <div class="for-user-data">
                                                                        <span>Password</span>
                                                                        <span class="password">************ <Link to="/ChangePassword" class="editprofilebtn change-password">Change Password</Link></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-12">
                                                    <div class="edit-profile-btn pe-xl-2 d-flex flex-column align-items-end pt-lg-4">
                                                        <Link to="/editprofile" class="btn primary-btn px-4">Edit Profile <img src={ArrowRight} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default MyProfile;