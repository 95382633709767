import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import OwnerSidebar from "../../components/OwnerSidebar"
import "../../assets/css/profile.css"
import { ArrowRight } from "../../Constants"
import { useNavigate } from 'react-router-dom';
const OwnerEditProfile = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/ownerprofile'); // navigate to the success page
    }
    return (
        <>
            <Header />
            <div className="contact-us">
                <div className="container">
                    <div className="banner-content">
                        <div className="hedadingDiv text-center">
                            <h3>Welcome To Asset Registry</h3>
                            <h1>Asset Owner</h1>
                            <p>Auctor elit sed vulputate mi sit amet mauris commodo quis. Ullamcorper a lacus vestibulum sed arcu
                                non
                                odio euismod lacinia. Etiam dignissim diam quis enim lobortis scelerisque. Luctus venenatis lectus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="profile-section">
                <div class="container">
                    <div class="profile-start">
                        <div class="row">
                            <OwnerSidebar />
                            <div class="col-lg-9 col-md-12">
                                <div class="profile_body h-100">
                                    <div class="single_tab ">
                                        <div class="title">
                                            <h3>My profile</h3>
                                        </div>
                                        <div class="edit-profile-tab mt-3">
                                            <div class="col-lg-10 col-md-12">
                                                <div class="form_start">
                                                    <form onSubmit={handleSubmit}>
                                                        <div class="row">
                                                            <div class="col-md-6 mt-3">
                                                                <label for="FirstName">First Name</label>
                                                                <input type="text" name="Email" className="form-control" id="FirstName" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="LastName">Last Name</label>
                                                                <input type="text" name="LastName" className="form-control" id="LastName" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Address">Phone</label>
                                                                <input type="tel" name="Phone" className="form-control" id="Phone" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="EmailAddress">Gender</label>
                                                                {/* <input type="email" name="Email" className="form-control" id="EmailAddress" placeholder="Enter here" /> */}
                                                                <select className="form-select form-control ">
                                                                    <option value="Select Here">Select Here</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="DateOfBirth">Date Of Birth</label>
                                                                <input type="tel" name="DateOfBirth" className="form-control" id="DateOfBirth" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Age">Age</label>
                                                                <input type="tel" name="Age" className="form-control" id="Age" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Driver's">Driver's License</label>
                                                                <input type="tel" name="DriverLicense" className="form-control" id="DriverLicense" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-12 mt-3">
                                                                <label for="PrimaryAddress">Primary Address</label>
                                                                <input type="tel" name="PrimaryAddress" className="form-control" id="PrimaryAddress" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-12 mt-3">
                                                                <label for="SecondaryAddress">Secondary Address</label>
                                                                <input type="tel" name="SecondaryAddress" className="form-control" id="SecondaryAddress" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="City">City</label>
                                                                <input type="tel" name="City" className="form-control" id="City" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="State">State</label>
                                                                <input type="tel" name="State" className="form-control" id="State" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Zipcode">Zipcode</label>
                                                                <input type="tel" name="Zipcode" className="form-control" id="Zipcode" placeholder="Your answer" />
                                                            </div>
                                                            <div class="col-md-6 mt-3">
                                                                <label for="Country">Country</label>
                                                                <input type="tel" name="Country" className="form-control" id="Country" placeholder="Your answer" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex mt-3  align-items-center justify-content-end flex-lg-row flex-column gap-lg-0 gap-4">
                                                            <button class="btn primary-btn center px-4" type="submit">Save Profile <img src={ArrowRight} alt="" /></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default OwnerEditProfile;