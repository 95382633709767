import React from "react"
import Header from "../../components/Header"
import Footer from "../../components/footer"
import "../../assets/css/auth.css"
import { Link, useNavigate } from 'react-router-dom'
import { ArrowRight, logo } from "../../Constants"
const ForgotPassword = () => {
    const navigate = useNavigate();

    // const newpasswordredirect = event => {
    //     event.preventDefault();

    //     // 👇️ redirect to /contacts
    //     navigate('/newpassword');
    // };
    return (
        <>
            {/* ForgotPassword Start Here */}
            <section class="auth-section auth-only">
                <Header />
                <div class="container px-0">
                    <div class="auth-start">
                        <div className="logo-box">
                            <Link to="/">
                                <figure><img src={logo} alt="site-logo" /></figure>
                            </Link>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-6 ps-md-0">
                                <div class="from-start h-100 d-flex flex-column justify-content-center position-relative">
                                    <div class="banner-content pt-0 pb-3">
                                        <div class="hedadingDiv">
                                            <h3>Welcome To Asset Registry</h3>
                                            <h1>Forget Password</h1>
                                            <p class="w-100 mb-0 pb-2">The best place to register your assets
                                            </p>
                                            <p className="medium-text">Please Enter Your Email Below</p>
                                            <p className="bold-text">We will send you a recovery Link</p>
                                        </div>
                                    </div>
                                    <form action="/newpassword">
                                        <div class="col-md-12 mb-3">
                                            <input type="text" class="form-control" placeholder="Email Address" />
                                        </div>
                                        <div class="d-flex justify-content-end mt-3">
                                            <button class="btn primary-btn w-100 d-block">Submit <img src={ArrowRight} alt="" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div class="col-md-5 pe-md-0">
                                <div class="auth-background">

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </section >
        </>
    )
}
export default ForgotPassword;