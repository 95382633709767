import React from "react";
import { profileimg, changeprofileimg } from "../Constants"
import { Link, useLocation } from 'react-router-dom'
const UserSidebar = () => {
    const location = useLocation();
    const pageName = location.pathname.substring(1);
    const PageLinks = [
        { to: 'ownerprofile', label: 'My Profile' },
        { to: 'ownerassetslist', label: 'Asset Verification' },
        { to: 'ownernotifications', label: 'Notifications' },
        { to: 'checkverification', label: 'Check Asset Verification' },
        { to: 'ownermeetingsschedule', label: 'Meeting With Authenticators' },
        { to: 'ownerchat', label: 'Chat With Authenticator' },
        { to: '', label: 'Logout' },
    ];
   

    return (
        <>
            <div class="col-lg-3 col-md-12">
                <div class="profile_tabs_list pe-lg-4">
                    <figure class="profile-img d-flex align-items-center justify-content-center">
                        <img src={profileimg} alt="" id="imgupload" />
                        <label for="uploadimage" class="change">
                            <img src={changeprofileimg} alt="" />
                            <input type="file" name="uploadimage" hidden id="uploadimage" accept="image/*" />
                        </label>
                    </figure>
                    <ul class="nav nav-pills all_tabs mb-3" id="pills-tab">
                        {PageLinks.map((link) => (
                            <li class="" role="presentation">
                                <Link
                                    key={link.to}
                                    to={'/'+link.to}
                                    className={pageName === link.to ? 'active' : ''}
                                >{link.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default UserSidebar;