import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import "../../assets/css/authenticate.css";
import {
	aboutimg,
	timelineimg,
	boximg,
	justiceLadyImg,
	authenticateImg,
	JusticeStep1,
	JusticeStep2,
	JusticeStep3,
	JusticeStep4,
} from "../../Constants";

const HowWeAuthenticate = () => {
	return (
		<>
			<Header />
			{/* <!-- authenticate sec Start Here --> */}
			<div class="authenticate">
				<div class="container">
					<div class="banner-content">
						<div class="row align-items-center">
							<div class="col-md-4 wow animate__animated animate__fadeInLeftBig">
								<h3>Welcome To Asset Registry</h3>
								<h1>how we authenticate</h1>
							</div>

							<div class="col-md-6 pe-lg-0 wow animate__animated animate__fadeInRightBig mt-4 pt-lg-1">
								<p>
									Elementum sagittis vitae et leo duis ut. Euismod quis viverra
									nibh cras pulvinar mattis nunc sed. Mattis vulputate enim
									nulla aliquet porttitor lacus. Vitae nunc sed velit dignissim
									sodales ut eu. Purus non enim praesent elementum facilisis
									leo. Elementum facilisis leo vel fringilla est. Elementum
									sagittis vitae et leo duis ut diam. Auctor neque vitae tempus
									quam pellentesque{" "}
								</p>
							</div>
						</div>
						{/* <div className="authenticate_box">
							<div className="img-box">
								<figure>
									<img
										src={justiceLadyImg}
										alt="justice-lady-img"
										className="img-fluid"
									/>
								</figure>
							</div>
						</div> */}
						{/* <div class="row">
                            <div class="col-md-4 wow animate__animated animate__fadeInLeftBig">
                                <div class="authenticate-box">
                                    <figure className='box-image'>
                                        <img src={boximg} alt="" />
                                    </figure>
                                    <div class="box-content">
                                        <h2>HOW WE AUTHENTICATE</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit
                                            amet. Adipiscing</p>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-4 wow animate__animated animate__fadeInLeftBig">
                                <div class="authenticate-box">
                                    <figure className='box-image'>
                                        <img src={boximg} alt="" />
                                    </figure>
                                    <div class="box-content">
                                        <h2>HOW WE AUTHENTICATE</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit
                                            amet. Adipiscing</p>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-4 wow animate__animated animate__fadeInLeftBig">
                                <div class="authenticate-box">
                                    <figure className='box-image'>
                                        <img src={boximg} alt="" />
                                    </figure>
                                    <div class="box-content">
                                        <h2>HOW WE AUTHENTICATE</h2>
                                        <p>Vitae congue eu consequat ac felis donec. Platea dictumst quisque sagittis purus sit
                                            amet. Adipiscing</p>
                                    </div>

                                </div>
                            </div>

                        </div> */}
					</div>
				</div>
			</div>
			{/* <!--authenticate sec End Here --> */}

			{/* <!-- About sec Start Here --> */}
			<section class="about-sec authenticate-page">
				<div class="container">
					<div class="row">
						<div class="col-md-6 wow animate__animated animate__fadeInLeftBig">
							{/* <img class="img-fluid" src={aboutimg} alt="About Img" /> */}
							<div className="img-box">
								<img class="img-fluid" src={authenticateImg} alt="About Img" />
							</div>
						</div>

						<div class="col-md-6 wow animate__animated animate__fadeInRightBig">
							<div class="ps-lg-5">
								<h3>Welcome To Asset Registry</h3>
								<h2>HOW WE AUTHENTICATE</h2>
								<p>
									Vitae congue eu consequat ac felis donec. Platea dictumst
									quisque sagittis purus sit amet. Adipiscing tristique risus
									nec feugiat in fermentum. Ut fauci.Ut eu sem integer vitae
									justo eget magna. Tempus iacmst quisque sagittis purus sit
									amet. Adipiscing tristique risus nec feugiat in fermentum. Ut
									fauci.Elementum sagittis vitae et leo duis ut. Euismod quis
									viverra nibh cras pulvinar mattis nunc sed. Mattis vulputate
									enim nulla aliquet porttitor lacus. Vitae nunc sed velit
									dignissim sodales ut eu. Purus non enim praesent elementum
									facilisis leo. Elementum
									<br />
									<br />
									Vitae congue eu consequat ac felis donec. Platea dictumst
									quisque sagittis purus sit amet. Adipiscing tristique risus
									nec feugiat in fermentum.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- About sec End Here --> */}

			{/* <!-- Our Story sec Start Here --> */}
			<section class="timeline-sec">
				<div class="container">
					<div class="hedadingDiv text-center">
						<h3>Welcome To Asset Registry</h3>
						<h2>HOW WE AUTHENTICATE</h2>
						<p>
							Auctor elit sed vulputate mi sit amet mauris commodo quis.
							Ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia.
							Etiam dignissim diam quis enim lobortis scelerisque. Luctus
							venenatis lectus
						</p>
					</div>

					<div class="row">
						<div class="col-md-5">
							<div class="col-md-12 box-1">
								<div class="timeline-box">
									<img
										class="img-fluid"
										src={JusticeStep1}
										alt="Timeline Img"
									/>
								</div>
								<div class="timeline-content">
									<h2>HOW WE AUTHENTICATE</h2>
									<p>
										Auctor elit sed vulputate mi sit amet mauris commodo quis.
										Ullamcorper a lacus vestibulum sed arcu non odio
									</p>
								</div>
							</div>

							<div class="col-md-12 box-3">
								<div class="timeline-box">
									<img
										class="img-fluid"
										src={JusticeStep2}
										alt="Timeline Img"
									/>
								</div>
								<div class="timeline-content">
									<h2>HOW WE AUTHENTICATE</h2>
									<p>
										Auctor elit sed vulputate mi sit amet mauris commodo quis.
										Ullamcorper a lacus vestibulum sed arcu non odio
									</p>
								</div>
							</div>
						</div>

						<div class="col-md-2 timeline-div">
							<ul class="timeline">
								<li>01</li>
								<li>02</li>
								<li>03</li>
								<li>04</li>
							</ul>
						</div>

						<div class="col-md-5">
							<div class="col-md-12 box-2">
								<div class="timeline-box">
									<img
										class="img-fluid"
										src={JusticeStep3}
										alt="Timeline Img"
									/>
								</div>
								<div class="timeline-content">
									<h2>HOW WE AUTHENTICATE</h2>
									<p>
										Auctor elit sed vulputate mi sit amet mauris commodo quis.
										Ullamcorper a lacus vestibulum sed arcu non odio
									</p>
								</div>
							</div>

							<div class="col-md-12 box-4">
								<div class="timeline-box">
									<img class="img-fluid" src={JusticeStep4} alt="Timeline Img" />
								</div>
								<div class="timeline-content">
									<h2>HOW WE AUTHENTICATE</h2>
									<p>
										Auctor elit sed vulputate mi sit amet mauris commodo quis.
										Ullamcorper a lacus vestibulum sed arcu non odio
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Our Story sec End Here --> */}
			<Footer />
		</>
	);
};
export default HowWeAuthenticate;
