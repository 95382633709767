import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// Pages Imported Here
import Home from "../Pages/Home/Home";
import Contacts from "../Pages/Contacts/Contacts";
import Faqs from "../Pages/Faqs/Faqs";
import HowItWorks from "../Pages/HowItWorks/HowItWorks";
import HowWeAuthenticate from "../Pages/HowWeAuthenticate/HowWeAuthenticate";
import Services from "../Pages/Services/Services";
import AboutUs from "../Pages/About/Aboutus";
import Blogs from "../Pages/Blogs/Blogs";
import BlogDetails from "../Pages/Blogs/BlogDetails";
import SignIn from "../Pages/Auth/SignIn";
import SignUp from "../Pages/Auth/SignUp";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import NewPassword from "../Pages/Auth/NewPassword";
import AssetOwner from "../Pages/Auth/AssetOwner";
import UploadAsset from "../Pages/UploadAsset/UploadAsset";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import AssetPayment from "../Pages/AssetPayment/AssetPayment";
import MyProfile from "../Pages/ProfileAuthenticator/MyProfile";
import EditProfile from "../Pages/ProfileAuthenticator/EditProfile";
import ChangePassword from "../Pages/ProfileAuthenticator/ChangePassword";
import AssetsList from "../Pages/ProfileAuthenticator/AssetsList";
import MeetingsSchedule from "../Pages/ProfileAuthenticator/MeetingsSchedule";
import UserNotifications from "../Pages/ProfileAuthenticator/UserNotifications";
import NewMeeting from "../Pages/ProfileAuthenticator/NewMeeting";
import NotVerifiedAsset from "../Pages/ProfileAuthenticator/NotVerifiedAsset";
import VerificationProgress from "../Pages/ProfileAuthenticator/VerificationProgress";
import VerifiedAsset from "../Pages/ProfileAuthenticator/VerifiedAsset";
import AssetVerified from "../Pages/ProfileAuthenticator/AssetVerified";
import AssetNotVerified from "../Pages/ProfileAuthenticator/AssetNotVerified";
import Certificate from "../Pages/ProfileAuthenticator/Certificate";
import AssetProgress from "../Pages/AssetProgress/AssetProgress";
// Owner Profile Pages Imported Here
import OwnerProfile from "../Pages/ProfileOwner/OwnerProfile";
import OwnerEditProfile from "../Pages/ProfileOwner/OwnerEditProfile";
import OwnerChangePassword from "../Pages/ProfileOwner/OwnerChangePassword";
import OwnerAssetsList from "../Pages/ProfileOwner/OwnerAssetsList";
import OwnerMeetingsSchedule from "../Pages/ProfileOwner/OwnerMeetingsSchedule";
import OwnerNotifications from "../Pages/ProfileOwner/OwnerNotifications";
import OwnerNotVerifiedAsset from "../Pages/ProfileOwner/OwnerNotVerifiedAsset";
import OwnerVerificationProgress from "../Pages/ProfileOwner/OwnerVerificationProgress";
import OwnerVerifiedAsset from "../Pages/ProfileOwner/OwnerVerifiedAsset";
import Chat from "../Pages/ProfileAuthenticator/Chat";
// import OwnerAssetVerified from "../Pages/ProfileOwner/OwnerAssetVerified";
import OwnerAssetNotVerified from "../Pages/ProfileOwner/OwnerAssetNotVerified";
import CheckVerification from "../Pages/ProfileOwner/CheckVerification";
import OwnerChat from "../Pages/ProfileOwner/OwnerChat";
// Css Imported Here
import "../assets/slick/slick.css"
import "../assets/slick/slick-theme.css"
import "../assets/css/animate.css"
import "../assets/css/variable.css"
// import "../assets/css/bootstrap.min.css" 
import "../assets/css/style.css"

const PublicRoutes = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contacts />} />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/howitworks" element={<HowItWorks />} />
                    <Route path="/HowWeAuthenticate" element={<HowWeAuthenticate />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/blogdetails" element={<BlogDetails />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/newpassword" element={<NewPassword />} />
                    <Route path="/assetowner" element={<AssetOwner />} />
                    <Route path="/uploadasset" element={<UploadAsset />} />
                    <Route path="/terms-conditions" element={<TermsAndConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/assetpayment" element={<AssetPayment />} />
                    <Route path="/assetpayment" element={<AssetPayment />} />
                    <Route path="/myprofile" element={<MyProfile />} />
                    <Route path="/editprofile" element={<EditProfile />} />
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/assetslist" element={<AssetsList />} />
                    <Route path="/meetingsschedule" element={<MeetingsSchedule />} />
                    <Route path="/usernotifications" element={<UserNotifications />} />
                    <Route path="/newmeeting" element={<NewMeeting />} />
                    <Route path="/notverifiedasset" element={<NotVerifiedAsset />} />
                    <Route path="/verificationprogress" element={<VerificationProgress />} />
                    <Route path="/verifiedasset" element={<VerifiedAsset />} />
                    <Route path="/assetverified" element={<AssetVerified />} />
                    <Route path="/assetnotverified" element={<AssetNotVerified />} />
                    <Route path="/certificate" element={<Certificate />} />
                    {/* Owner Profile Start Here */}
                    <Route path="/Ownerprofile" element={<OwnerProfile />} />
                    <Route path="/Ownereditprofile" element={<OwnerEditProfile />} />
                    <Route path="/Ownerchangepassword" element={<OwnerChangePassword />} />
                    <Route path="/Ownerassetslist" element={<OwnerAssetsList />} />
                    <Route path="/Ownermeetingsschedule" element={<OwnerMeetingsSchedule />} />
                    <Route path="/Ownernotifications" element={<OwnerNotifications />} />
                    <Route path="/Ownernotverifiedasset" element={<OwnerNotVerifiedAsset />} />
                    <Route path="/Ownerverificationprogress" element={<OwnerVerificationProgress />} />
                    <Route path="/Ownerverifiedasset" element={<OwnerVerifiedAsset />} />
                    {/* <Route path="/Ownerassetverified" element={<OwnerAssetVerified />} /> */}
                    <Route path="/Ownerassetnotverified" element={<OwnerAssetNotVerified />} />
                    <Route path="/checkverification" element={<CheckVerification />} />
                    <Route path="/ownerchat" element={<OwnerChat />} />
                    <Route path="/assetprogress" element={<AssetProgress />} />
                    <Route path="/chat" element={<Chat />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}
export default PublicRoutes;